import { useTranslations } from "@/components/context/translations";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { api } from "@lib/client";
import type { Ticket } from "@lib/ticket";
import { type Values } from "@lib/utils";
import { useMutation } from "@tanstack/react-query";
import { type FieldValues, useForm } from "react-hook-form";
import { z } from "zod";
import { ajaxHeadersWithCSRFToken } from "@lib/utils";

type VerificationModalProps = {
  encodedActivityId: string;
  isDialogOpen: boolean;
  onDialogOpenChange: (open: boolean) => void;
  formValues: FieldValues | null;
  ticket: Ticket | undefined;
};

const formSchema = z.object({
  code: z
    .string({
      required_error: "The code you entered is incorrect",
    })
    .min(4, { message: "The code you entered is incorrect" })
    .max(4),
});

type FormData = z.infer<typeof formSchema>;

export function VerificationModal({
  encodedActivityId,
  isDialogOpen,
  onDialogOpenChange,
  formValues,
  ticket,
}: VerificationModalProps) {
  const t = useTranslations();
  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (code: string) => {
      const res = await api.claim.$post(
        {
          json: {
            encodedActivityId,
            formData: {
              code,
              ...(formValues as Values),
            },
            ticket,
          },
        },
        {
          headers: ajaxHeadersWithCSRFToken(),
        },
      );

      return res.json();
    },
    onSuccess: (data) => {
      if (!data.success && "errors" in data) {
        form.setError("code", { message: data.errors.code });
        return;
      }

      if ("redirect" in data) {
        window.location.href = data.redirect;
        return;
      }
    },
  });

  async function onSubmit({ code }: FormData) {
    await mutateAsync(code);
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={onDialogOpenChange}>
      <DialogContent className="w-full max-w-[350px]">
        <DialogHeader>
          <DialogTitle>{t("Verification")}</DialogTitle>
          <DialogDescription>
            {t("We just sent you an email with a code. Please enter it below.")}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="grid gap-3"
            onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
          >
            <FormField
              control={form.control}
              name="code"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      type="text"
                      maxLength={4}
                      className="text-center text-2xl font-bold tracking-[12px]"
                      style={
                        {
                          "--field-border-size": "1px",
                          "--input-border-color": "hsl(var(--primary))",
                        } as React.CSSProperties
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <p className="text-sm">
              {t("Didn't receive the email?")}{" "}
              <Button
                variant="link"
                type="button"
                onClick={() => {
                  if (!formValues?.email) return;
                  void api.verify.$post(
                    {
                      json: {
                        encodedActivityId,
                        email: formValues.email as string,
                      },
                    },
                    {
                      headers: ajaxHeadersWithCSRFToken(),
                    },
                  );
                }}
              >
                {t("Try again")}
              </Button>
            </p>
            <Button
              type="submit"
              disabled={isPending}
              aria-disabled={isPending}
            >
              {t("Verify")}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
